import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.PAYMENTS_CREATED,
  frozenConfig: {
    payments: {
      accountNumber: 113859,
    },
  },
};
